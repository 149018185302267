// CandidatosRow.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Image, Badge, Stack, Card } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'
import novotos from '../img/no-votos.png'; // Asegúrate de ajustar la ruta según la ubicación real de tu imagen


//const BASE_URL = 'http://localhost:3007/api';
const BASE_URL = 'https://api.bdatosgeneral.xyz/estadisticas/api'

const NoVotos = (props) => {
    const refreshInterval = 1;
    const navigate = useNavigate();

    const token = localStorage.getItem('token_3');
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
    const [noVotos, setNoVotos] = useState(null)
    const [loading, setLoading] = useState(false)


    const obtenerNoVotos = () => {
        setLoading(true)
        axios.get(BASE_URL + `/novotos/${props.cargo}`, config)
            .then((response) => {
                setNoVotos(response.data.novotos);

            })
            .catch((error) => {
                handleApiError(error);
            }).finally(() => {
                setLoading(false)
            });
    };

    const obtenerNovotosCircuito = () => {
        setLoading(true)
        axios.get(BASE_URL + `/novotos/${props.cargo}/${props.circuito.value}`, config)
            .then((response) => {
                setNoVotos(response.data.novotos);

            })
            .catch((error) => {
                handleApiError(error);
            }).finally(() => {
                setLoading(false)
            });
    };


    // ... Otras definiciones de estado y lógica ...

    // Definir un efecto para observar cambios en las propiedades
    useEffect(() => {
        // Llamar a la función obtenerTopFive inicialmente
        if (!props.circuito) {

            obtenerNoVotos();
        } else {

            obtenerNovotosCircuito()
        }
        // Configurar un intervalo para la actualización periódica
        const interval = setInterval(() => {


            if (!props.circuito) {

                obtenerNoVotos();
            } else {

                obtenerNovotosCircuito()
            }
        }, refreshInterval * 60 * 1000); // Cambié 100 a 1000 para que sea en milisegundos

        // Limpiar el intervalo cuando el componente se desmonte o cuando cambie refreshInterval
        return () => clearInterval(interval);
    }, [props.cargo, props.circuito, refreshInterval]); // Observar cambios en las propiedades y refreshInterval




    const handleApiError = (error) => {
        if (error.message === 'Network Error') {
            // Handle the connection refused error here
            toast.error('Sin conexion con el servidor', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            console.error('Connection refused. The server may not be running or is unreachable.');
        } else {


            switch (error.response && error.response.status) {
                case 401:
                    navigate('/login');
                    break;
                case 403:
                    toast.error('Acceso denegado', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    break;
                case 500:
                    toast.error('Error interno del servidor ' + error.mensaje, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    break;
                default:
                    console.error(error);
                    break;
            }
        }
    };

    function convertirString(inputString) {
        // Convierte la primera letra en mayúscula y las demás en minúsculas
        const formattedString = inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();

        // Reemplaza "_" por espacio " "
        const finalString = formattedString.replace(/Comando_electoral/g, 'Comando Electoral');

        return finalString;
    }


    return (


        <Row>

            {noVotos && noVotos.map((item, i) => (
                <Col md={2}>
                    <Card bg={(item.Tipo =="AFIRMATIVO")? 'success':'light'}>
                        <Card.Body>
                            <Row>

                                <Col style={{ textAlign: "center", color:(item.Tipo =="AFIRMATIVO")? 'white':'black'}}>
                                    <b> {convertirString(item.Tipo)}</b>
                                </Col>

                            </Row>
                            <Row>
                                <Col></Col>
                                <Col style={{ fontSize: 30 , color:(item.Tipo =="AFIRMATIVO")? 'white':'black'}}>
                                    <b>{(parseInt(item.votos) > 0) ? item.votos : 0} </b>
                                </Col>
                                <Col></Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>

            ))}
        





        </Row>



    );
};

export default NoVotos;
