import React, {useState} from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import axios from 'axios';
import '../css/LoginForm.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const LoginForm = () => {
    const { login, setPartido } = useContext(AuthContext);
    const navigate = useNavigate();
    const [error, setError] = useState('');

    //const URL = 'http://localhost:3007/api/login'
    const URL = 'https://api.bdatosgeneral.xyz/estadisticas/api/login'

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const user = event.target.elements.username.value;
        const password = event.target.elements.password.value;
        // Realizar llamada a la API para validar credenciales
        axios
            .post(URL, { user, password })
            .then((response) => {
                // Validar respuesta y establecer estado de inicio de sesión
                if (response.status === 200 && response.data.status === 'OK') {
                    login(true);

                    setPartido(response.data.partido)
                    localStorage.setItem('isLoggedIn', 'true');
                    localStorage.setItem('token_3', response.data.token)
                    localStorage.setItem('token_3_partido', response.data.partido)

                    navigate('/home');

                } else {
     
                    toast.error('Atención: ' + response.data.mensaje, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            })
            .catch((error) => {
                handleApiError(error);
            });

    };


    const handleApiError = (error) => {
        if (error.message === 'Network Error') {
            // Handle the connection refused error here
            toast.error('Sin conexion con el servidor', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            console.error('Connection refused. The server may not be running or is unreachable.');
        } else {


            switch (error.response && error.response.status) {
                case 401:
                    toast.error('Permisos o token invalidos', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    navigate('/login');

                    break;
                case 403:
                    toast.error('Acceso denegado', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    break;
                case 500:
                    toast.error('Error interno del servidor ' + error.mensaje, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    break;
                default:
                    console.error(error);
                    break;
            }
        }
    }
    return (
        <div className="login-container">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <form className="login-form" onSubmit={handleFormSubmit}>
                <h2>Iniciar sesión</h2>
                <div className="form-group">
                    <label htmlFor="username">Usuario:</label>
                    <input type="text" id="username" />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Contraseña:</label>
                    <input type="password" id="password" />
                </div>
                <button type="submit">Iniciar sesión</button>
                {error && <div className="error-message">{error}</div>}
            </form>
        </div>
    );
};

export default LoginForm;


