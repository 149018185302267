// CandidatosRow.js

import React, { useState, useEffect } from 'react';
import Candidato from './Candidato';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'
import votopng from '../img/voto_afimativo.png'; // Asegúrate de ajustar la ruta según la ubicación real de tu imagen


//const BASE_URL = 'http://localhost:3007/api';
const BASE_URL = 'https://api.bdatosgeneral.xyz/estadisticas/api'

const CandidatosRow = (props) => {
    const refreshInterval = 1;
    const navigate = useNavigate();
    const [totalVotos, setTotalVotos] = useState(null)
    const token = localStorage.getItem('token_3');
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
    const [candidatosFive, setCandidatosFive] = useState(null)
    const [loading, setLoading] = useState(false)


    const obtenerTopFive = () => {
        setLoading(true)
        axios.get(BASE_URL + `/topfive/${props.cargo}`, config)
            .then((response) => {
                let arrayFiltrado;

                if (props.cargo === 1 || props.cargo === 2) {
                    arrayFiltrado = response.data.candidato.slice(0, 2);;

                } else { arrayFiltrado = response.data.candidato.filter(objeto => objeto.Nombre !== "LISTA HACEMOS"); }

                setCandidatosFive(arrayFiltrado);
                if (response.data.candidato.length > 0) {
                    setTotalVotos((response.data.candidato[0].Total_votos))
                } else {
                    setTotalVotos((0))
                }
            })
            .catch((error) => {
                handleApiError(error);
            }).finally(() => {
                setLoading(false)
            });
    };
    const obtenerTopFiveCircuito = () => {
        setLoading(true)
        axios.get(BASE_URL + `/topfive/${props.cargo}/${props.circuito.value}`, config)
            .then((response) => {

                let arrayFiltrado;

                if (props.cargo === 1 || props.cargo === 2) {
                    arrayFiltrado = response.data.candidato.slice(0, 2);;

                } else { arrayFiltrado = response.data.candidato.filter(objeto => objeto.Nombre !== "LISTA HACEMOS"); }

                setCandidatosFive(arrayFiltrado);
                if (response.data.candidato.length > 0) {
                    setTotalVotos((response.data.candidato[0].Total_votos))
                } else {
                    setTotalVotos((0))
                }
            })
            .catch((error) => {
                handleApiError(error);
            }).finally(() => {
                setLoading(false)
            });
    };


    // ... Otras definiciones de estado y lógica ...

    // Definir un efecto para observar cambios en las propiedades
    useEffect(() => {
        // Llamar a la función obtenerTopFive inicialmente
        if (!props.circuito) {

            obtenerTopFive();
        } else {

            obtenerTopFiveCircuito()
        }
        // Configurar un intervalo para la actualización periódica
        const interval = setInterval(() => {


            if (!props.circuito) {

                obtenerTopFive();
            } else {

                obtenerTopFiveCircuito()
            }
        }, refreshInterval * 60 * 1000); // Cambié 100 a 1000 para que sea en milisegundos

        // Limpiar el intervalo cuando el componente se desmonte o cuando cambie refreshInterval
        return () => clearInterval(interval);
    }, [props.cargo, props.circuito, refreshInterval]); // Observar cambios en las propiedades y refreshInterval




    const handleApiError = (error) => {
        if (error.message === 'Network Error') {
            // Handle the connection refused error here
            toast.error('Sin conexion con el servidor', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            console.error('Connection refused. The server may not be running or is unreachable.');
        } else {


            switch (error.response && error.response.status) {
                case 401:
                    navigate('/login');
                    break;
                case 403:
                    toast.error('Acceso denegado', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    break;
                case 500:
                    toast.error('Error interno del servidor ' + error.mensaje, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    break;
                default:
                    console.error(error);
                    break;
            }
        }
    };




    return (

        <>
            <Row>



                {candidatosFive && candidatosFive.map((candidato, index) => (
                    <Candidato
                        key={index}
                        imagen={candidato.IMG}
                        nombre={candidato.nombreCandidato || candidato.agrupacion}
                        votos={candidato.Total_columna}
                        agrupacion={candidato.agrupacion}
                        imgAgrupacion={candidato.imagen_agrupacion}
                        porcentaje={candidato.PORCENTAJE}
                        color={candidato.Color}
                        isLoading={loading}
                    />

                ))}
                {(candidatosFive && candidatosFive.length === 0) ?
                    <Row>
                        <Col>
                            < Alert key={0} variant='danger'>
                                No hay votos registrados para este cargo y/o circuito.
                            </Alert>
                        </Col>
                    </Row>
                    : <></>
                }

            </Row>
           
           



        </>




    );
};

export default CandidatosRow;
