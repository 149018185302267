import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Container, Row, Col, Image, Card, Accordion, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Sidebar from '../components/Sidebar';
import CandidatosRow from '../components/CandidatosRow';
import urna from '../img/urna-electoral.png'; // Asegúrate de ajustar la ruta según la ubicación real de tu imagen
import escuela from '../img/escuela.png'; // Asegúrate de ajustar la ruta según la ubicación real de tu imagen
import votantes from '../img/votantes.png'; // Asegúrate de ajustar la ruta según la ubicación real de tu imagen
import concejalesimg from '../img/concejales.png';

import Placeholder from 'react-bootstrap/Placeholder';


import Spinner from 'react-bootstrap/Spinner';
import NoVotos from '../components/NoVotos';

//const BASE_URL = 'http://localhost:3007/api';
const BASE_URL = 'https://api.bdatosgeneral.xyz/estadisticas/api'

const Home = () => {

  const navigate = useNavigate();
  const token = localStorage.getItem('token_3');
  const config = {
    headers: { 'Authorization': `Bearer ${token}` }
  };
  const [selectedCargo, setSelectedCargo] = useState({ value: 1, label: 'Presidente' })
  const [selectedCircuito, setSelectedCircuito] = useState(null)
  const [concejalesL, setConcejales] = useState(null)

  const [circuitos, setCircuitos] = useState()

  const [escuelasCargadas, setEsceulasCargadas] = useState(null);
  const [escuelasCargadasCircuito, setEsceulasCargadasCircuito] = useState([]);
  const [loadingMesas, setLoadingMesas] = useState(false)
  const [loadingMesasCircuito, setLoadingMesasCircuito] = useState(false)

  const [mesasescrutadas, setMesasEscrutadas] = useState(null);
  const [mesasescrutadasCircuito, setMesasEscrutadasCircuito] = useState([]);
  const [loadingMesasEscrutadas, setLoadingMesasEscrutadas] = useState(false)
  const [loadingMesasEscrutadasCircuito, setLoadingMesasEscrutadasCircuito] = useState(false)

  const [participacion, setParticipacion] = useState(null);
  const [participacionCircuito, setParticipacionCircuito] = useState([]);
  const [loadingParticipacion, setLoadingParticipacion] = useState(false)
  const [loadingParticipacionCircuito, setLoadingParticipacionCircuito] = useState(false)

  const [votos, setVotos] = useState([]);
  const [noVotos, setNoVotos] = useState(null);

  const [refreshInterval, setRefreshInterval] = useState(1);
  const [lastUpdate, setLastUpdate] = useState('');

  const [cargos, setCargo] = useState('');



  const participacionVotantes = () => {
    setLoadingParticipacion(true)
    axios.get(BASE_URL + '/participacion', config)
      .then((response) => {

        setParticipacion(response.data.participacion);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => {
        setLoadingParticipacion(false)
      })
  };

  const participacionVotantesCircuito = () => {
    setLoadingParticipacionCircuito(true)
    axios.get(BASE_URL + '/participacioncircuito', config)
      .then((response) => {

        setParticipacionCircuito(response.data.participacion);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => {
        setLoadingParticipacionCircuito(false)
      });
  };


  const mesasHabilitadas = () => {
    setLoadingMesas(true)

    axios.get(BASE_URL + '/mesashabilitadas', config)
      .then((response) => {

        setEsceulasCargadas(response.data.mesas);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => {
        setLoadingMesas(false)
      });
  };

  const mesasHabilitadasCircuito = () => {
    setLoadingMesasCircuito(true)
    axios.get(BASE_URL + '/mesashabilitadasporcircuito', config)
      .then((response) => {

        setEsceulasCargadasCircuito(response.data.mesas);
      })
      .catch((error) => {
        handleApiError(error);
      }).finally(() => {
        setLoadingMesasCircuito(false)
      });


  };

  const mesasEscrutadas = () => {
    setLoadingMesasEscrutadas(true)
    axios.get(BASE_URL + '/mesasescrutadas', config)
      .then((response) => {

        setMesasEscrutadas(response.data.mesas);

      })
      .catch((error) => {
        handleApiError(error);
      }).finally(() => {
        setLoadingMesasEscrutadas(false)
      });
  };

  const mesasEscrutadasCircuito = () => {
    setLoadingMesasEscrutadasCircuito(true)
    axios.get(BASE_URL + '/mesasescrutadascircuito', config)
      .then((response) => {
        setMesasEscrutadasCircuito(response.data.mesas);

      })
      .catch((error) => {
        handleApiError(error);
      }).finally(() => {
        setLoadingMesasEscrutadasCircuito(false)
      });
  };

  const obtenerConcejales = () => {
    axios.get(BASE_URL + '/concejales', config)
      .then((response) => {
        const consejales = response.data.concejales.filter(objeto => parseFloat(objeto.division) >= 1)

        let conse = asignarConcejales(consejales)

        setConcejales(conse)

      })
      .catch((error) => {
        handleApiError(error);
      }).finally(() => {
      });
  };

  function asignarConcejales(objeto) {

    const concejales = objeto

    const totalConcejales = 12;

    const asignadosActuales = concejales.reduce((total, concejal) => total + parseInt(concejal.asignados), 0);
    let restoTotal = totalConcejales - asignadosActuales;


    if (restoTotal <= 0) {
      concejales.forEach(element => {
        element.Concejales = element.asignados

      });
      return objeto;
    }

    // Asignar proporcionalmente el "resto" a cada concejal
    concejales.forEach(concejal => concejal.Concejales = Math.floor(concejal.asignados));

    // Ordenar los concejales con asignados por el valor del campo "resto" de mayor a menor
    concejales.sort((a, b) => parseFloat(b.resto) - parseFloat(a.resto));


    // Asignar el resto de concejales de a 1 a los concejales con asignados
    for (let i = 0; i < concejales.length; i++) {
      if (restoTotal > 0) {
        concejales[i].Concejales += 1;
        restoTotal -= 1;
      }

    }

    // Ordenar los concejales por la cantidad de votos de mayor a menor
    concejales.sort((a, b) => parseInt(b.votosAgrupaciones) - parseInt(a.votosAgrupaciones));



    return concejales;
  }



  const votosPorLista = () => {
    axios.get(BASE_URL + '/votosporlista', config)
      .then((response) => {

        setVotos(response.data.votos);
        // ObtenernoVotos()
      })
      .catch((error) => {
        handleApiError(error);
      });


  };

  const obtenerCircuitos = () => {
    axios.get(BASE_URL + '/circuitos', config)
      .then((response) => {


        const options = response.data.circuito.map((c) => ({
          value: c.CircuitoID, // Cambia "id" por el campo que representa el valor único de cada opción
          label: 'Circuito: ' + c.CircuitoID  // Cambia "nombre" por el campo que representa el texto a mostrar en el select
        }));
        setCircuitos([{ value: 99, label: "Total" }, ...options]);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const ObtenernoVotos = () => {
    const tipo = ['NULO', 'RECURRIDO', 'IMPUGNADO', 'COMANDO_ELECTORAL', 'BLANCO'];

    const requests = tipo.map(element => {
      return axios.get(BASE_URL + '/novotos/' + element, config)
        .then(response => {
          return response.data.novotos[0];
        })
        .catch(error => {
          handleApiError(error);
          return null;
        });
    });

    Promise.all(requests)
      .then(responses => {
        // Aquí tienes todas las respuestas en un solo array
        const noVotosArray = responses.filter(response => response !== null);
        // Puedes hacer lo que desees con noVotosArray, por ejemplo, almacenarlo en un estado
        setNoVotos(noVotosArray);
      })
      .catch(error => {
        console.error('Error en alguna solicitud:', error);
      });

  };

  useEffect(() => {
    setCargo(
      [{ value: 8, label: 'Intendente' },
      { value: 1, label: 'Presidente' },
      { value: 2, label: 'Parlasur Nacional' },
      { value: 3, label: 'Senador Nacional' },
      { value: 4, label: 'Diputado Nacional' },
      { value: 5, label: 'Parlasur Regional' },
      { value: 6, label: 'Gobernador' },
      { value: 7, label: 'Senador Provincial' }]
    )
    obtenerCircuitos();
  }, [])


  useEffect(() => {
    votosPorLista();


    mesasHabilitadas();
    mesasHabilitadasCircuito();

    mesasEscrutadas();
    mesasEscrutadasCircuito();

    participacionVotantes();
    participacionVotantesCircuito();

    obtenerConcejales();
    setLastUpdate(new Date().toLocaleTimeString());

    const interval = setInterval(() => {
      setLastUpdate(new Date().toLocaleTimeString());
      votosPorLista();
      obtenerCircuitos();

      mesasHabilitadas();
      mesasHabilitadasCircuito();

      mesasEscrutadas();
      mesasEscrutadasCircuito();

      participacionVotantes();
      participacionVotantesCircuito();
      obtenerConcejales();

    }, refreshInterval * 60 * 1000); // Convertir minutos a milisegundos

    return () => clearInterval(interval);
  }, [refreshInterval]);

  // Obtener opciones del select desde el endpoint


  const reiniciar = () => {
    if (window.confirm("Reiniciar")) {
      axios.get(BASE_URL + '/reiniciar', config)
        .then((response) => {

          setVotos(response.data.votos);

        })
        .catch((error) => {
          handleApiError(error);
        });
    }

  }




  const handleApiError = (error) => {
    if (error.message === 'Network Error') {
      // Handle the connection refused error here
      toast.error('Sin conexion con el servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error('Connection refused. The server may not be running or is unreachable.');
    } else {


      switch (error.response && error.response.status) {
        case 401:
          navigate('/login');
          break;
        case 403:
          toast.error('Acceso denegado', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        case 500:
          toast.error('Error interno del servidor ' + error.mensaje, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        default:
          console.error(error);
          break;
      }
    }
  };

  const handleManualUpdate = () => {

    votosPorLista();
    obtenerCircuitos();

    mesasHabilitadas();
    mesasHabilitadasCircuito();

    mesasEscrutadas();
    mesasEscrutadasCircuito();

    participacionVotantes();
    participacionVotantesCircuito();
    setLastUpdate(new Date().toLocaleTimeString());
  };

  const getCargo = () => {
    return selectedCargo;
  }

  return (
    <>
      <Sidebar />
      <br></br>

      <Container>
        <Row >
          <Col md={3}>

            <p>Última actualización: {lastUpdate}</p>
          </Col>


          <Col md={3}>
            {/* <Form.Control
              as="select"
              onChange={(e) => setRefreshInterval(e.target.value)}
              value={refreshInterval}
            >
              <option value="1">1 minuto</option>
              <option value="2">2 minutos</option>
              <option value="3">3 minutos</option>
              <option value="4">4 minutos</option>
              <option value="5">5 minutos</option>
            </Form.Control> */}
            <p></p>
            {/* <Button variant="primary" className="ml-2" onClick={handleManualUpdate}>
              Actualizar Ahora
            </Button> */}

          </Col>
          <Col md={6}></Col>
        </Row>
        <Row>


          <Row>
            <Col md={4}>
              <Card >
                {/* <Card.Header>Carpetas cargadas
                </Card.Header> */}
                <Card.Body>
                  <Row>
                    <Col>



                      {(loadingMesas) ?
                        <Alert variant="primary">
                          <Row>
                            <Col md={3}>
                              <img src={escuela} style={{ width: "auto", height: '50px' }}></img>
                            </Col>
                            <Col md={5}>
                              Carpetas cargadas: <b><Placeholder xs={3} /> </b>
                            </Col>
                            <Col md={4} >
                              <Row>
                                <Col>
                                  <small>Carga</small>
                                  <h5><Placeholder xs={11} /></h5>
                                </Col>

                              </Row>

                            </Col>
                          </Row>

                        </Alert>
                        :
                        <Alert variant="primary">
                          <Row>
                            <Col md={3}>
                              <img src={escuela} style={{ width: "auto", height: '50px' }}></img>
                            </Col>
                            <Col md={5}>
                              Carpetas cargadas: <b>{escuelasCargadas && escuelasCargadas[0].Escuelas_habilitadas} </b>
                            </Col>
                            <Col md={4} >
                              <Row>
                                <Col>
                                  <small>Completado</small>
                                  <h5>{escuelasCargadas && escuelasCargadas[0].PORCENTAJE}%</h5>
                                </Col>

                              </Row>

                            </Col>
                          </Row>

                        </Alert>
                      }
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Carpetas cargadas por circuito</Accordion.Header>
                          <Accordion.Body>
                            <Table striped bordered hover>
                              <thead style={{ fontSize: 12 }}>
                                <tr>
                                  <th>Circuito</th>
                                  <th>Cargada</th>
                                  <th>Total</th>
                                  <th>%</th>
                                </tr>
                              </thead>
                              <tbody>

                                {(loadingMesasCircuito) ?
                                  <tr key={0}>
                                    <td><Spinner animation="border" variant="success" /></td>
                                    <td><Spinner animation="border" variant="success" /></td>
                                    <td><Spinner animation="border" variant="success" /></td>
                                    <td><Spinner animation="border" variant="success" /></td>
                                  </tr>

                                  :
                                  escuelasCargadasCircuito && escuelasCargadasCircuito.map((item, i) => (
                                    <tr key={i}>
                                      <td>{item.CircuitoID}</td>
                                      <td>{item.escuelas_habilitadas}</td>
                                      <td>{item.total_escuelas}</td>
                                      <td>{item.PORCENTAJE}%</td>
                                    </tr>
                                  ))
                                }

                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card >
                {/* <Card.Header>Mesas cargadas
                </Card.Header> */}
                <Card.Body>
                  <Row>
                    <Col>

                      {(loadingMesasEscrutadas) ?
                        <Alert variant="warning">
                          <Row>
                            <Col md={3}>
                              <img src={urna} style={{ width: "auto", height: '50px' }}></img>
                            </Col>
                            <Col md={5}>
                              Mesas escrutadas: <b><Placeholder xs={3} /> </b>
                            </Col>
                            <Col md={4} >
                              <Row>
                                <Col>
                                  <small>Escrutado</small>
                                  <h5><Placeholder xs={11} /></h5>
                                </Col>

                              </Row>

                            </Col>
                          </Row>

                        </Alert>
                        :
                        <Alert variant="warning">
                          <Row>
                            <Col md={3}>
                              <img src={urna} style={{ width: "auto", height: '50px' }}></img>
                            </Col>
                            <Col md={5}>
                              Mesas escrutadas: <b>{mesasescrutadas && mesasescrutadas[0].ACTAS_CARGADAS || 0} </b>
                            </Col>
                            <Col md={4} >
                              <Row>
                                <Col>
                                  <small>Escrutado</small>
                                  <h5>{mesasescrutadas && mesasescrutadas[0].PORCENTAJE}%</h5>
                                </Col>

                              </Row>

                            </Col>
                          </Row>

                        </Alert>
                      }
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Mesas cargadas por circuito</Accordion.Header>
                          <Accordion.Body>
                            <Table striped bordered hover>
                              <thead style={{ fontSize: 12 }}>
                                <tr>
                                  <th>Circuito</th>
                                  <th>Cargadas</th>
                                  <th>Total</th>
                                  <th>%</th>
                                </tr>
                              </thead>
                              <tbody>

                                {(loadingMesasEscrutadasCircuito) ?
                                  <tr key={0}>
                                    <td><Spinner animation="border" variant="success" /></td>
                                    <td><Spinner animation="border" variant="success" /></td>
                                    <td><Spinner animation="border" variant="success" /></td>
                                    <td><Spinner animation="border" variant="success" /></td>
                                  </tr>

                                  :
                                  mesasescrutadasCircuito && mesasescrutadasCircuito.map((item, i) => (
                                    <tr key={i}>
                                      <td>{item.CircuitoID}</td>
                                      <td>{item.ActasCargadas}</td>
                                      <td>{item.MesasTotales}</td>
                                      <td>{item.PorcentajeCargadas}%</td>

                                    </tr>
                                  ))
                                }


                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

            </Col>

            <Col md={4}>
              <Card >

                <Card.Body>
                  <Row>
                    <Col>



                      {(loadingParticipacion) ?
                        <Alert variant="success">
                          <Row>
                            <Col md={3}>
                              <img src={votantes} style={{ width: "auto", height: '50px' }}></img>
                            </Col>
                            <Col md={5}>
                              Votaron <b><Placeholder xs={4} /> </b> personas
                            </Col>
                            <Col md={4} >
                              <Row>
                                <Col>
                                  <small>Participación</small>
                                  <h5><Placeholder xs={11} /></h5>
                                </Col>

                              </Row>

                            </Col>
                          </Row>

                        </Alert>
                        :
                        <Alert variant="success">
                          <Row>
                            <Col md={3}>
                              <img src={votantes} style={{ width: "auto", height: '50px' }}></img>
                            </Col>
                            <Col md={5}>
                              Votaron <b>{participacion && participacion[0].VOTANTES} </b> personas
                            </Col>
                            <Col md={4} >
                              <Row>
                                <Col>
                                  <small>Participación</small>
                                  <h5>{participacion && participacion[0].PORCENTAJE_VOTOS_CARGADAS}%</h5>
                                </Col>

                              </Row>

                            </Col>
                          </Row>

                        </Alert>
                      }
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Participación por circuito</Accordion.Header>
                          <Accordion.Body>
                            <Table striped bordered hover>
                              <thead style={{ fontSize: 12 }}>
                                <tr>
                                  <th>Circuito</th>
                                  <th>Votantes</th>
                                  <th>Total</th>
                                  <th>%</th>
                                </tr>
                              </thead>
                              <tbody>

                                {(loadingParticipacionCircuito) ?
                                  <tr key={0}>
                                    <td><Spinner animation="border" variant="success" /></td>
                                    <td><Spinner animation="border" variant="success" /></td>
                                    <td><Spinner animation="border" variant="success" /></td>
                                    <td><Spinner animation="border" variant="success" /></td>

                                  </tr>

                                  :

                                  participacionCircuito && participacionCircuito.map((item, i) => (
                                    <tr key={i}>
                                      <td>{item.CircuitoID}</td>
                                      <td>{item.VOTANTES}</td>
                                      <td>{item.TOTAL_VOTOS_ACTAS}</td>
                                      <td>{item.PORCENTAJE_VOTOS_CARGADAS}%</td>

                                    </tr>
                                  ))

                                }


                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

            </Col>

          </Row>



          <Row>
            <p></p>
            <Col>
              <Card>
                {/* <Card.Header> Listas: Estas viendo <b>{selectedCargo.label}</b> {selectedCircuito && "del circuito " + selectedCircuito.label}</Card.Header> */}
                <Card.Body>
                  <Row>

                    <Col md={12} xs={12}>
                      <Select
                        options={circuitos}

                        value={selectedCircuito}
                        onChange={(option) => {
                          console.log(option)
                          if (option.value === 99) {

                            setSelectedCargo({ value: 1, label: "Presidente" })
                            setSelectedCircuito(null)

                          } else {
                            setSelectedCircuito(option)
                          }
                        }
                        }
                        placeholder="Selecciona un circuito"
                      />
                      <br></br>

                      {/* <Button onClick={handleLoadMesaInfo}>Elegir</Button> */}

                      <br></br>

                    </Col>

                  </Row>

                  <Row>
                    <Col>
                      <CandidatosRow cargo={selectedCargo.value} circuito={selectedCircuito} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <NoVotos cargo={selectedCargo.value} circuito={selectedCircuito} />
                    </Col>
                  </Row>
                </Card.Body>


              </Card>
              <p></p>


            </Col>

          </Row>

          <Row>

          </Row>


          <p></p>
        </Row>



      </Container >

    </>
  );
};

export default Home;
