// Candidato.js

import React from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import candidato from '../img/candidato_sin_imagen.png'; // Asegúrate de ajustar la ruta según la ubicación real de tu imagen
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Placeholder from 'react-bootstrap/Placeholder';
import noimagen from '../img/no-image.png'; // Asegúrate de ajustar la ruta según la ubicación real de tu imagen


const Candidato = ({ imagen, nombre, votos, agrupacion, imgAgrupacion, porcentaje, color, isLoading }) => {

  const imagenCandidato = imagen ? imagen : candidato;


  return (
    <Col md={6} xs={12}>

      <Card>
        <Card.Header>
          {(!isLoading) ?
            <Row>
              <Col md={2} xs={12}> <Image src={imgAgrupacion || noimagen} alt={nombre} style={{ width: '50px' }} rounded /></Col>
              <Col md={7} xs={12} className="align-items-start text-left">{agrupacion}</Col>
              <Col md={3} xs={12}>
                <Row>
                  <Col>
                    Votos:  {votos || 0}
                  </Col>
                </Row>
                <Row>
                  <Col style={{ fontSize: 18 }}>
                    <b>
                      {porcentaje || 0}%
                    </b>
                  </Col>
                </Row>
              </Col>
            </Row>
            :
            <Row>
              <Col md={2} xs={12}> <Placeholder style={{ width: '50px', height: '50px' }} /> </Col>
              <Col md={8} xs={12}>  <Placeholder xs={9} /> </Col>
              <Col md={2} xs={12}>
                <Row>
                  <Col>
                    <Placeholder xs={5} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Placeholder xs={5} />
                  </Col>
                </Row>
              </Col>
            </Row>
          }



        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              {(isLoading) ?
                <Row>
                  <Col md={2}>
                    <Placeholder style={{ width: '80px', height: '80px' }} />
                  </Col>

                  <Col md={10} style={{ fontSize: 25 }}>
                    <Row>
                      <Col>
                        <Placeholder   xs={12}/>
                      </Col>
                    </Row>
                    <Row>
                      <div>
                   
                        <Placeholder xs={12} />
                      </div>
                    </Row>

                  </Col>
                </Row>
                :
                <Row>
                  <Col md={2}>
                    <Image src={imagenCandidato} alt={nombre} style={{ width: '80px' }} rounded />
                  </Col>
                  <Col md={10} style={{ fontSize: 25 }}>
                    <Row>
                      <Col>
                        {nombre}
                      </Col>
                    </Row>
                    <Row>
                  <p></p>
                      <div>
                        <ProgressBar now={porcentaje} key={1} label={`${porcentaje}%`} />
                      </div>

                    </Row>

                  </Col>
                </Row>
              }</Col>
          </Row>

        </Card.Body>
      </Card>

      <p> </p>
    </Col >
  );
};

export default Candidato;
